@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Proxima-Nova-Font.otf') format('otf'), /* Safari, Android, iOS */
       
}

body {
  overflow: hidden !important;
}

.MuiGrid-root {
  margin-top: 0px !important;
}

.MuiGrid-root::-webkit-scrollbar {
  display: none;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}
